import { createStore, Event } from 'effector';

import { applyReducers } from 'effector-utils';

import { ShowAlertPayload } from 'state/alert';

import {
  setDisplayAlert,
  setPromptUserSignIn,
  setReferrer,
  setSignInLandingPage,
  setReferrerCallback,
  setVisitPaymentDetails,
  setInitialLandingPath,
  setSignInLandingPageState,
} from './actions';

import type { ReferrerCallbackFn } from './actions';

type RouteState = {
  referrer: string | null;
  signInLandingPage: string | null;
  signInLandingPageState: { isPurchasingPass: boolean } | null;
  referrerCallback: ReferrerCallbackFn | null;
  visitPaymentDetails: {
    visitId: number;
    totalPrice: number;
    paymentErrorMessage?: string;
  } | null;
  displayAlert: ShowAlertPayload | null;
  promptUserSignIn: boolean;
  initialPath: string | null;
};

const initialState: RouteState = {
  referrer: null,
  signInLandingPage: null,
  signInLandingPageState: null,
  referrerCallback: null,
  visitPaymentDetails: null,
  displayAlert: null,
  promptUserSignIn: false,
  initialPath: null,
};

const store = createStore(initialState);

type Reducers = {
  setReferrer: {
    action: Event<string | null>;
    reducer: (state: RouteState, payload: string | null) => RouteState;
  };
  setSignInLandingPage: {
    action: Event<string | null>;
    reducer: (state: RouteState, payload: string | null) => RouteState;
  };
  setSignInLandingPageState: {
    action: Event<{ isPurchasingPass: boolean } | null>;
    reducer: (state: RouteState, payload: { isPurchasingPass: boolean } | null) => RouteState;
  };
  setReferrerCallback: {
    action: Event<ReferrerCallbackFn | null>;
    reducer: (state: RouteState, payload: ReferrerCallbackFn | null) => RouteState;
  };
  setPromptUserSignIn: {
    action: Event<boolean>;
    reducer: (state: RouteState, payload: boolean) => RouteState;
  };
  setVisitPaymentDetails: {
    action: Event<{
      visitId: number;
      totalPrice: number;
      paymentErrorMessage?: string;
    } | null>;
    reducer: (
      state: RouteState,
      payload: {
        visitId: number;
        totalPrice: number;
        paymentErrorMessage?: string;
      } | null,
    ) => RouteState;
  };
  setDisplayAlert: {
    action: Event<ShowAlertPayload | null>;
    reducer: (state: RouteState, payload: ShowAlertPayload | null) => RouteState;
  };
  setInitialLandingPath: {
    action: Event<string>;
    reducer: (state: RouteState, payload: string) => RouteState;
  };
};

export const reducers: Reducers = {
  setReferrer: {
    action: setReferrer,
    reducer: (state, payload) => ({
      ...state,
      referrer: payload,
    }),
  },
  setSignInLandingPage: {
    action: setSignInLandingPage,
    reducer: (state, payload) => ({
      ...state,
      signInLandingPage: payload,
    }),
  },
  setSignInLandingPageState: {
    action: setSignInLandingPageState,
    reducer: (state, payload) => ({
      ...state,
      signInLandingPageState: payload,
    }),
  },
  setReferrerCallback: {
    action: setReferrerCallback,
    reducer: (state, payload) => ({
      ...state,
      referrerCallback: payload,
    }),
  },
  setPromptUserSignIn: {
    action: setPromptUserSignIn,
    reducer: (state, payload) => ({
      ...state,
      promptUserSignIn: payload,
    }),
  },
  setVisitPaymentDetails: {
    action: setVisitPaymentDetails,
    reducer: (state, payload) => ({
      ...state,
      visitPaymentDetails: payload,
    }),
  },
  setDisplayAlert: {
    action: setDisplayAlert,
    reducer: (state, payload) => ({
      ...state,
      displayAlert: payload,
    }),
  },
  setInitialLandingPath: {
    action: setInitialLandingPath,
    reducer: (state, payload) => ({
      ...state,
      initialLandingPath: state.initialPath || payload,
    }),
  },
};

export default applyReducers({ store, reducers });
