import { createEvent } from 'effector';

import type { ShowAlertPayload } from 'state/alert';

export type ReferrerCallbackFn = ({ success, data }: { success: boolean; data?: any }) => void;

// TODO: Stop using reach router state to store container referrer
export const setReferrer = createEvent<string | null>();

// Callback when returning back to referrer
// Current usage is opening the valet checkout page after adding a card
export const setReferrerCallback = createEvent<ReferrerCallbackFn | null>();

// Accepts a path name used to navigate
// Ex Usage: setSignInLandingPage('/membership');
export const setSignInLandingPage = createEvent<string | null>();

// TODO: This passes state to the page you land on. We can refactor to persist query params
// This is used along with `setSignInLandingPage`
export const setSignInLandingPageState = createEvent<{ isPurchasingPass: boolean } | null>();

// Will prompt the user if needed
export const setPromptUserSignIn = createEvent<boolean>();

export const setVisitPaymentDetails = createEvent<{
  visitId: number;
  totalPrice: number;
  paymentErrorMessage?: string;
} | null>();

export const setInitialLandingPath = createEvent<string>();

export const setDisplayAlert = createEvent<ShowAlertPayload | null>();
