import { createEffect } from 'effector';

import { ViolationService } from 'apps/admin/services/ViolationService';
import { apiFetch } from 'utils/http';

export const fetchEnums = createEffect({
  handler: ({ isAdmin }: { isAdmin: boolean }) => apiFetch(isAdmin ? '/admin/enums' : '/enums'),
});

export const fetchLPStates = createEffect({
  handler: () => apiFetch('/enums/license-plate-states'),
});

export const fetchInvoiceLineItemProductTypes = createEffect({
  handler: () => apiFetch('/v2/finance-operations/invoices/product-types'),
});

export const fetchInvoiceLineItemValidationTypeIds = ({ entId }: { entId: string }) =>
  apiFetch(`/admin/enterprise/${entId}/location-sign-list`);

// TODO: we will add this data to response of /enum
export const fetchOperator = createEffect({
  handler: () => apiFetch('/v2/operator'),
});

export const fetchSubscriptionCancellationReasons = createEffect({
  handler: () => apiFetch('/admin/subscription/cancellation-reasons'),
});

export const fetchViolationStatuses = createEffect({
  handler: ViolationService.fetchViolationStatuses,
});

export const fetchViolationCloseReasons = createEffect({
  handler: ViolationService.fetchViolationCloseReasons,
});
