/**
 * This renders all the customer app routes and handles the initial
 *
 * Tried to use AuthenticatedRoute.tsx for the routes,
 *   but there are edge cases in the customer app that doesn't work with this approach.
 * Because of this, we had to render all Routes in the same Switch
 *   and render the Redirects dynamically, so that we don't get Redirected randomly
 */

import { useEffect, useState } from 'react';

import { useUnit } from 'effector-react';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';

import SignUpRoutes from 'apps/customer/routes/SignUpRoutes';
import { paymentStore } from 'apps/customer/state/payment';
import { routeStore } from 'apps/customer/state/route';
import { todoStore } from 'apps/customer/state/todo';
import { userStore } from 'apps/customer/state/user';
import { visitStore } from 'apps/customer/state/visit';

import { vehicleStore } from 'state/vehicle';

import {
  authPaths,
  DefaultOnboardingRoutes,
  ProtectedRoutes,
  publicPaths,
  StartParkingOnboardingRoutes,
  TaskItemRoutes,
} from './paths';
import {
  customerRoutesList,
  onboardingRoutesList,
  publicRoutesList,
  taskRoutesList,
} from './routes';

import { isProd } from 'constants/FeatureFlags';

type Props = {
  userDataInitialized: boolean;
};

export default function CustomerRoutes({ userDataInitialized }: Props) {
  const location = useLocation();
  const history = useHistory();

  // tracks if the initial page load routing has occurred yet
  const [hasPageLoaded, setHasPageLoaded] = useState(false);

  const {
    user,
    authenticated,
    enterpriseUser,
    status: {
      verifyCodeSuccess,
      signUpRegisterSuccess,
      parseInvitationCodeSuccess: isInviteWelcome,
    },
  } = useUnit(userStore);
  const { hasRequiredTasks, hasFetchedTodo, onboardingTasks, hasPaymentTask, hasPendingTasks } =
    useUnit(todoStore);
  const { signInLandingPage, promptUserSignIn } = useUnit(routeStore);
  const { defaultPayment } = useUnit(paymentStore);
  const { vehicles } = useUnit(vehicleStore);
  const { isMostRecentVisitOpen } = useUnit(visitStore);

  /**
   * on page load navigator
   * waits for all the required data to be loaded before making the decision
   *
   * there are 3 states the user can be in when the first land on the page.
   * depending on the state, we need to handle routing differently.
   * to evaluate the state, we need to fetch user data, and todos
   *
   * if public path, go to public path
   * if enterprise invite flow, diff flow than this one
   *
   * Step Checklist
   * registered users - user has verified phone number and accepted ToS
   *   1. if user has confirmVisit task, display express STP landing page - /select-visit-duration
   *   2. if user has express variation:
   *     a. if user has paymentTask, go to /tasks (will deprecate once we handle payment overdue)
   *     b. if user has any required tasks (not paymentTask):
   *       i. if only task is missedEntry, go to /tasks (missed entry page is WIP, so use legacy)
   *       ii. else go to - /onboard
   *     c. if user has non-required tasks (addPaymentMethod, etc), go to home or visit page - /
   *     d. else go to /
   *
   * unregistered user (QR code scan or only verified phone number. user session found)
   *   1. if user has confirmVisit task, display express STP landing page - /select-visit-duration
   *   2. if user has express variation:
   *     a. if user has any required tasks (not paymentTask), go to express vision - /onboard
   *     b. if user has non-required tasks, go to /onboard (skippable addPaymentMethod only?)
   *     c. if pathname is valid unauth route, like /welcome (invite link), go to pathname
   *     d. else /sign-in
   *
   * no user session (visits app.metropolis.io or mpolis.io/welcome)
   *   1. go to /sign-in
   */
  useEffect(() => {
    // todo: what's the cleanest way of bypassing this crazy flow
    // should we forget about forcing users onto pages based on user state? maybe forcing on some pages only?
    // ex. if someone is in express flow and they visit app.metropolis.io/
    //     can we not redirect them to /onboard and just put them onto dashboard?
    //     they're most likely already on /onboard or will probably rescan anyways right?
    if (enterpriseUser) {
      return;
    }
    // revert to old flow
    if (publicPaths.indexOf(location.pathname as any) > -1) {
      setHasPageLoaded(true);
      return;
    }

    // get if user is set
    if (!hasPageLoaded && authenticated !== null) {
      if (authenticated) {
        // registered users
        if (hasFetchedTodo) {
          if (hasPaymentTask) {
            // 2a
            if (!location.pathname.startsWith(TaskItemRoutes.Tasks)) {
              history.push(TaskItemRoutes.Tasks);
            }
          } else if (hasRequiredTasks) {
            // 2b
            if (onboardingTasks?.missedEntryStartVisit && onboardingTasks.list.length === 1) {
              // 2bi
              if (!location.pathname.startsWith(ProtectedRoutes.ConfirmMissedEntryPage)) {
                history.push(ProtectedRoutes.ConfirmMissedEntryPage);
              }
            } else {
              // 2bii
              history.push(
                history.location.search && !isProd
                  ? StartParkingOnboardingRoutes.Onboard + history.location.search
                  : StartParkingOnboardingRoutes.Onboard,
              );
            }
          } else if (authPaths.indexOf(location.pathname as any) === -1) {
            // 2c, 2d
            // if visit history page, vehicle settings (wildcard matching)
            if (
              !location.pathname.match(/^\/receipt\/[0-9A-Za-z-]+$/) &&
              !location.pathname.match(/^\/visit\/[0-9A-Za-z]+$/) &&
              !location.pathname.match(/^\/vehicle\/[0-9A-Za-z]+$/) &&
              !location.pathname.match(/^\/vehicle\/[0-9A-Za-z]+\/drivers$/) &&
              !location.pathname.match(/^\/pass\/[0-9A-Za-z-]+$/)
            ) {
              history.push(ProtectedRoutes.ForwardSlash);
            }
          }

          setHasPageLoaded(true);
        }
      } else if (Object.keys(user).length > 0) {
        if (hasFetchedTodo) {
          if ((hasRequiredTasks && !hasPaymentTask) || (!hasRequiredTasks && hasPendingTasks)) {
            // 2a - only show the required payment task after they've registered (overdue payment)
            // 2b - if there are pending tasks that are not required, then have them fill it out
            history.push(
              history.location.search && !isProd
                ? StartParkingOnboardingRoutes.Onboard + history.location.search
                : StartParkingOnboardingRoutes.Onboard,
            );
          } else if (onboardingRoutesList.indexOf(location.pathname) === -1) {
            // 2c, 2d
            history.push(DefaultOnboardingRoutes.SignIn);
          }
          setHasPageLoaded(true);
        }
      } else if (location.pathname.match(/^\/claim\/[0-9A-Za-z]+$/)) {
        // user trying to claim via link / QR
        history.push(location.pathname);
        setHasPageLoaded(true);
      } else {
        // no user session
        history.push(DefaultOnboardingRoutes.SignIn);
        setHasPageLoaded(true);
      }
    }
  }, [
    hasPaymentTask,
    hasPendingTasks,
    user,
    authenticated,
    hasFetchedTodo,
    hasRequiredTasks,
    onboardingTasks,
    enterpriseUser,
    hasPageLoaded,
    location,
    history,
  ]);

  /**
   * enterprise user flow
   * if missing email or not registered, go to sign up
   * if missing vehicle, go vehicle
   * else, go membership
   *
   * will there be a problem if user has todos?
   */
  useEffect(() => {
    if (hasPageLoaded) {
      return;
    }
    if (enterpriseUser) {
      const { isRegistered, email } = user;
      if (email && isRegistered) {
        if (vehicles?.length === 0) {
          history.push(DefaultOnboardingRoutes.Vehicle + history.location.search);
          setHasPageLoaded(true);
        } else if (vehicles && vehicles.length > 0) {
          history.push(`/membership${history.location.search}`);
          setHasPageLoaded(true);
        }
      } else {
        history.push(`${DefaultOnboardingRoutes.SignIn}${history.location.search}`);
        setHasPageLoaded(true);
      }
    }
  }, [enterpriseUser, user, vehicles, hasPageLoaded, history]);

  /**
   * NOTE: is this still used other than for visit receipts?
   *
   * Override landing page. Some links or QR codes will route the user somewhere else
   **/
  useEffect(() => {
    // If signing in, then SignUpContainer will handle the sign in logic
    if (signUpRegisterSuccess === null && verifyCodeSuccess === null) {
      if (
        userDataInitialized &&
        authenticated &&
        hasRequiredTasks === false && // required tasks take priority
        signInLandingPage
      ) {
        if (promptUserSignIn) {
          if (!vehicles?.length) {
            // need to collect more information
            // again, ideally this is a /task route
            history.push(DefaultOnboardingRoutes.Vehicle);
            return;
          }
          if (defaultPayment === null) {
            history.push(DefaultOnboardingRoutes.PaymentMethod);
            return;
          }
        }
        history.push(signInLandingPage || ProtectedRoutes.ForwardSlash);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    userDataInitialized,
    promptUserSignIn,
    verifyCodeSuccess,
    signUpRegisterSuccess,
    // Purposely leaving out vehicles, hasRequiredTasks, and defaultPayment
    // so this hook isn't run if any of these are updated
  ]);

  return (
    <Switch>
      {publicRoutesList.map(
        ({ component: Component, ...props }) =>
          Component && <Component key={props.path} {...props} />,
      )}

      {authenticated &&
        hasFetchedTodo &&
        hasRequiredTasks &&
        taskRoutesList().map(
          ({ component: Component, ...props }) =>
            Component && (
              <Component
                key={typeof props.path === 'string' ? props.path : props.path[0]}
                {...props}
              />
            ),
        )}

      {hasPageLoaded &&
        authenticated !== null &&
        customerRoutesList({
          isInviteWelcome,
          isMostRecentVisitOpen,
        }).map((route) => {
          if (route === null) {
            return null;
          }
          const { component: Component, ...props } = route;
          return (
            Component && (
              <Component
                key={typeof props.path === 'string' ? props.path : props.path[0]}
                {...props}
              />
            )
          );
        })}

      {hasPageLoaded && authenticated !== null && <SignUpRoutes />}

      {hasPageLoaded && authenticated === false && <Redirect to={DefaultOnboardingRoutes.SignIn} />}
      {hasPageLoaded && authenticated && hasRequiredTasks === false && (
        <Redirect to={ProtectedRoutes.ForwardSlash} />
      )}
      {hasPageLoaded && authenticated && hasRequiredTasks && <Redirect to={TaskItemRoutes.Tasks} />}
    </Switch>
  );
}
