import { useEffect } from 'react';

import { KEYCLOAK_ENABLED } from 'config/env';
import { KeycloakService } from 'services/KeycloakService';
import { heapIdentifyUser, heapIdentityReset, isSameHeapUser } from 'utils/heap';

import { User } from 'types/api';

/**
 * We reset the user identity if the userId and heap identity mismatch
 *
 * @param userId
 * @param authenticated - only start tracking authenticated users
 */
export function useHeapIdentifyUser({
  user,
  authenticated,
}: {
  user?: User | null;
  authenticated?: boolean | null;
}) {
  useEffect(() => {
    if (KEYCLOAK_ENABLED && KeycloakService.isLoggedIn()) {
      const userUuid = KeycloakService.getTokenParsed()?.sub;
      const email = KeycloakService.getTokenParsed()?.email;
      if (isSameHeapUser({ userUuid })) {
        heapIdentifyUser({ userUuid, email });
      } else {
        heapIdentityReset();
        heapIdentifyUser({ userUuid, email });
      }
    } else if ((KEYCLOAK_ENABLED || authenticated) && user) {
      const { id: userId, email } = user;
      if (isSameHeapUser({ userId })) {
        heapIdentifyUser({ userId, email });
      } else {
        heapIdentityReset();
        heapIdentifyUser({ userId, email });
      }
    }
  }, [authenticated, user]);
}
