import { createEffect, createEvent } from 'effector';

import { setLastVehicleWasPreviouslyAdded } from 'apps/customer/state/onboard';
import { fetchTodoItems } from 'apps/customer/state/todo';
import { fetchCustomerVisits } from 'apps/customer/state/visit';
import { trackFailedAddVehicle } from 'apps/customer/utils/heapOnboardTracking';
import { apiFetch, APIResponse } from 'utils/http';

import type { Vehicle } from 'types/api';

type failPayload = {
  response: APIResponse;
  fallbackMessage: string;
};

function trackAddOrUpdateVehicleFailure({ response, fallbackMessage }: failPayload) {
  const { search, errorCodes } = {
    search: response?.data?.search || 'null',
    errorCodes: response?.data?.errorCodes || [],
  };

  const mappedCodes = errorCodes.reduce((acc: { [x: string]: string }, key: string | number) => {
    acc[key] = 'true';
    return acc;
  }, {});

  trackFailedAddVehicle({
    addVehicleErrorMessage: response?.error || fallbackMessage,
    search: search || '',
    ...mappedCodes,
  });
}

export const fetchMostRecentVehicle = createEffect({
  handler: () => apiFetch('/customer/most-recent-vehicle'),
});

export const fetchVehicles = createEffect({
  handler: () => apiFetch('/customer/vehicles'),
});

export const fetchVehicleMakes = createEffect({
  handler: () => apiFetch('/v2/vehicle/makes'),
});

export const fetchVehicleModels = createEffect({
  handler: async ({ makeId }: { makeId: number }) => {
    const response = await apiFetch('/vehicle/models', { params: { makeId } });

    if (response.success) {
      response.data.makeId = makeId;
    }

    return response;
  },
});

export const fetchVehicleYears = createEffect({
  handler: async ({ modelId }: { modelId: number }) => {
    const response = await apiFetch('/vehicle/model-years', { params: { modelId } });

    if (response.success) {
      response.data.modelId = modelId;
    }

    return response;
  },
});

/**
 * We need to fetch updated todos and possibly visits after making changes to a user's account
 * since there might be more actionable tasks a customer needs to do
 *
 * Fetching visits is required during sign up
 * Will prevent a jump from "About Metropolis"
 * to "Open Visit" if user comes from scanning QR validation.
 * @param refreshVisits - boolean
 */
async function updateClientState({ refreshVisits }: { refreshVisits?: boolean }) {
  const refreshTodoPromise = fetchTodoItems();
  let fetchCustomerVisitsPromise;
  if (refreshVisits) {
    fetchCustomerVisitsPromise = fetchCustomerVisits();
  }
  return Promise.all([refreshTodoPromise, fetchCustomerVisitsPromise]);
}

export interface AddVehiclePayload {
  licensePlateText?: string;
  licensePlateStateId?: number | string | null;
  makeId?: number | string | null;
  modelId?: number | string | null;
  modelYearId?: number | string | null;
  color?: string | null;
  isTemporaryVehicle?: boolean | null;
  temporaryVehicleEndTime?: number | null;
  refreshVisitsOnSuccess?: boolean;
  suggestedEventUuid?: string | null;
  suggestedEventConfirmed?: boolean | null;
}

export interface UpdateVehiclePayload extends AddVehiclePayload {
  vehicleId: number;
}

export const addVehicle = createEffect({
  handler: async ({
    licensePlateText,
    licensePlateStateId,
    makeId,
    modelId,
    modelYearId,
    color,
    isTemporaryVehicle,
    temporaryVehicleEndTime,
    refreshVisitsOnSuccess,
    suggestedEventUuid,
    suggestedEventConfirmed,
  }: AddVehiclePayload) => {
    const response = await apiFetch('/customer/vehicle', {
      method: 'POST',
      body: {
        licensePlate: {
          state: { id: licensePlateStateId },
          text: licensePlateText,
        },
        makeId,
        modelId,
        modelYearId,
        color,
        isTemporaryVehicle,
        temporaryVehicleEndTime,
        suggestedEventUuid: suggestedEventUuid || null,
        suggestedEventConfirmed,
      },
    });

    let todoResp;
    if (response.success) {
      [todoResp] = await updateClientState({ refreshVisits: refreshVisitsOnSuccess });
      response.data.todoItems = todoResp?.data?.todoItems;
      if (response?.data?.vehicle) {
        const { vehicle } = response.data;
        setLastVehicleWasPreviouslyAdded(vehicle.sharedVehicleRole.name === 'Pending Shared');
      }
    } else {
      trackAddOrUpdateVehicleFailure({
        response,
        fallbackMessage: 'Error: Failed to add vehicle',
      });
    }

    return response;
  },
});

export const updateVehicle = createEffect({
  handler: async ({
    vehicleId,
    licensePlateText,
    licensePlateStateId,
    makeId,
    modelId,
    modelYearId,
    color,
    isTemporaryVehicle,
    temporaryVehicleEndTime,
    refreshVisitsOnSuccess,
  }: UpdateVehiclePayload) => {
    const response = await apiFetch(`/customer/vehicle/${vehicleId}`, {
      method: 'PUT',
      body: {
        licensePlate: {
          state: { id: licensePlateStateId },
          text: licensePlateText,
        },
        makeId,
        modelId,
        modelYearId,
        color,
        isTemporaryVehicle,
        temporaryVehicleEndTime,
      },
    });

    let todoResp;
    if (response.success) {
      [todoResp] = await updateClientState({ refreshVisits: refreshVisitsOnSuccess });
      response.data.todoItems = todoResp?.data?.todoItems;
    } else {
      trackAddOrUpdateVehicleFailure({
        response,
        fallbackMessage: 'Error: Failed to update vehicle',
      });
    }

    return response;
  },
});

export const updateVehicleType = createEffect({
  handler: async ({
    vehicleId,
    color,
    makeId,
    modelId,
    licensePlate, // returns req err without LP
  }: {
    vehicleId: number;
    color?: string | null;
    makeId: number | null;
    modelId: number | null;
    licensePlate: {
      state: { id: number | string | null };
      text?: string;
    };
  }) => {
    const response = await apiFetch(`/customer/vehicle/${vehicleId}`, {
      method: 'PUT',
      body: {
        licensePlate,
        makeId,
        modelId,
        color,
      },
    });

    if (response.success) {
      await updateClientState({ refreshVisits: true });
    }

    return response;
  },
});

export const removeVehicle = createEffect({
  handler: ({ vehicleId }: { vehicleId: number }) =>
    apiFetch(`/customer/vehicle/${vehicleId}`, { method: 'DELETE' }),
});

// userId used when approving, phoneNumber when adding yourself
export const addSharedDriver = createEffect({
  handler: async ({
    vehicleId,
    phoneNumber,
    userId,
  }: {
    vehicleId: number;
    phoneNumber?: string;
    userId?: number;
  }) => {
    const response = await apiFetch(`/customer/vehicle/${vehicleId}/add-driver`, {
      method: 'POST',
      body: {
        phoneNumber,
        userId,
      },
    });

    return response;
  },
});

export const removeSharedDriver = createEffect({
  handler: async ({ vehicleId, userId }: { vehicleId: number; userId: number }) => {
    const response = await apiFetch(`/customer/vehicle/${vehicleId}/remove-driver/${userId}`, {
      method: 'DELETE',
    });

    return response;
  },
});

export const promoteSharedDriverToPrimary = createEffect({
  handler: async ({ vehicleId, userId }: { vehicleId: number; userId: number }) => {
    const response = await apiFetch(
      `/customer/vehicle/${vehicleId}/request-promote-driver/${userId}`,
      { method: 'POST' },
    );

    return response;
  },
});

export const acceptBecomingPrimaryDriver = createEffect({
  handler: async ({
    vehicleId,
    requestId,
    accepted,
  }: {
    vehicleId: number;
    requestId: number;
    accepted: boolean;
  }) => {
    const response = await apiFetch(
      `/customer/vehicle/${vehicleId}/confirm-request/${requestId}?confirm=${accepted}`,
      { method: 'POST' },
    );

    return response;
  },
});

export const resetVehicleAsyncStatuses = createEvent();

/**
 * Sets the next vehicle for a STP visit
 */
export const setSelectedVehicleForSTPVisit = createEvent<Vehicle>();
